
import { collection, getDoc, getDocs, query, Timestamp, where, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { auth, db } from '../../firebase.config'
import Etoiles from '../formulaire/etoiles/etoiles';
import './style.css'
import plume from '../../assets/icones/activités/pen.png'


function Vignette(props) {
    const [userName, setUserName] = useState('');

    function getDate(TimeStamp) {
        const date = new Date(TimeStamp.seconds * 1000)
        console.log(date.toLocaleDateString())
        return 'Le ' + date.toLocaleDateString("fr-FR", { weekday: 'long', month: 'long', day: 'numeric' })

    }
    async function getNameUser() {
        // const rep = await getDocs(query(collection(db,'Utilisateurs'), where('mail', '==', auth.currentUser.email)))
        const rep = await getDoc(doc(db, 'Utilisateurs', props.doc.data()['idUser']));
        console.log(props.doc.data()['idUser']);
        try {
            console.log(rep.data())

            setUserName(rep.data()['nom'].toUpperCase() + ' ' + rep.data()['prenom'])
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        getNameUser()
    }, [userName])


    return <article className={props.doc.data()['categorie']}>
        <h3>{getDate(props.doc.data()['dateActivite'])}</h3>
        <div className='details'>
            <p className='categorie'>{props.doc.data()['categorie'].toUpperCase()}</p>
            <div className='cadreHoraire'>
                <p>{props.doc.data()['heureDepart']} - {props.doc.data()['heureDArrive']}</p>
            </div>
            <p className='titre'>TITRE : {props.doc.data()['nomActivite']}</p>
            <p className='lieu'>Lieu : {props.doc.data()['lieu']}</p>
            <p className='accompagnant'>Accompagné par : {userName}</p>
            <p className='places'>Places : {props.doc.data()['nombrePlaces']}</p>
            <p className='prix'>Prix : {props.doc.data()['frais']} €</p>
            {
                !props.mine &&
                <><p>Départ à {props.doc.data()['heureDepart']}</p><p>Retour à {props.doc.data()['heureDArrive']}</p></>
            }
        </div>
        {
            props.mine &&
            <><div className='detailsPlus'>
                <div className='Inscrits'><p className='lesInscrits'>Inscrits({props.doc.data()['inscrits'].length > 0 ? props.doc.data()['inscrits'].length : 0}): </p>
                    {props.doc.data()['inscrits'].map((doc) => {
                        return <p className='inscrit' >{doc}</p>
                    })}
                </div>
                <p>Départ à {props.doc.data()['heureDepart']}</p>
                <p>Retour à {props.doc.data()['heureDArrive']}</p>
                <Etoiles etoiles={props.doc.data()['indice']} />
                <p>Text complementaire : <br />
                    {props.doc.data()['textComplementaire']}
                </p>
                <button className='modification' onClick={props.modifier}><p>Modifier</p> <img src={plume} alt="plume" /></button>
            </div></>
        }
    </article>


}

export default Vignette;