import logo from '../../assets/logo/LOGO1.png'
import './style.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logOutIcon from '../../assets/icones/logout.png'
import { auth } from '../../firebase.config';
import { signOut } from 'firebase/auth'
import { MdArrowBackIos } from 'react-icons/md'

function Header(props) {
    const navigate = useNavigate();
    const [conected, SetConected] = useState(false);

    function logOutUser() {
        signOut(auth);
        navigate('/login')

    }

    useEffect(() => {
        if (auth.currentUser != null) {

            console.log('userLoged : ' + auth.currentUser.email)
            SetConected(true)

        } else {
            console.log('not loged')
            console.log(auth)
            SetConected(false);
            navigate('/login')
        }
    })
    return <header>
        {
            conected ? <button className='logOut' onClick={() => { logOutUser() }}><p>Se déconnecter</p><img src={logOutIcon} alt="logo logOut"></img></button> : <button className='logOut' onClick={() => navigate('/login')}><p>Se connecter</p><img src={logOutIcon} alt="logo logOut"></img></button>
        }
        <p className='backButton' onClick={() => { navigate('/acceuil') }}><MdArrowBackIos className='back' /></p>
        <h1>{props.titre}</h1>
        <button className="logo" onClick={() => { navigate("/acceuil") }}></button>
    </header>
}

export default Header;