
import './style.css';
import NavigationBar from '../../component/NavigationBar/NavigationBar';
import Header from '../../component/header/Header';
import { useState, useEffect } from 'react';
import { getDocs, collection, doc } from 'firebase/firestore';
import { auth, db } from '../../firebase.config';
import Vignette from '../../component/vignette/activite';

function CalendrierEscapades() {
    const [activite, setActivite] = useState([]);

    async function getActivite() {
        var lesActivites = []
        const date = new Date()
        try {
            const res = await getDocs(collection(db, 'Activites'))
            console.log(res)
            res.forEach((doc) => {


                if (doc.data().dateActivite.seconds * 1000 > date.getTime()) { lesActivites.push(doc) }
            })
            setActivite(lesActivites)


        } catch (error) {
            console.log('ERRRRRORRRR')
            console.log(error)

        }


    }


    useEffect(() => {
        getActivite()
    }, [])
    return <div className="Calendrier">
        <NavigationBar />
        <main >
            <Header titre={"Calendrier des Escapades"} />
            <div className='lesVignettes' >
                {activite.map((doc, key) => {
                    return <Vignette doc={doc} mine={false} />

                })}


            </div>
        </main>
    </div>
}
export default CalendrierEscapades;