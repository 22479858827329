import './style.css';
import logo from '../../assets/logo/LOGO1-logo-seul.png'


function Waiter(){
    return <div className='waiter'>
        <div className='cadre'>
        <img src={logo} alt="" />
        </div>
    </div>
}
export default Waiter;