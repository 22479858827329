import {sendPasswordResetEmail, getAuth} from 'firebase/auth';

export class ForgotPasswordService{
    

    static async forgotPassword(email){
        console.log(email)
        const auth = getAuth();
       
        
          const res=   await sendPasswordResetEmail(auth,email).then(()=>{
                console.log('Password reset email sent!')
                return true;
            }).catch((error)=>{
                console.log(error);
                 return false;
            })

            return res;

        
        
    }



}