import './style.css'
import { useState, useEffect } from 'react';
import { ForgotPasswordService } from '../../services/ForgotPassword.js';
import { useNavigate } from 'react-router-dom';



function ForgotPassword(props) {
    const [email, setEmail] = useState();
    const [waiting, setWaiting] = useState(false);
    const [done, setDone] = useState(false);
    const [phrase, setPhrase] = useState();
    const navigate = useNavigate()

    async function sendEmailReinit(email) {
        setWaiting(true)
        const result = await ForgotPasswordService.forgotPassword(email)
        if (result) {
            setWaiting(false)
            setDone(true)
            setPhrase('Un email vous a été envoyé pour réinitialiser votre mot de passe. Vérifiez vos spams si vous ne le trouvez pas.')
        } else {
            console.log(result)
            setWaiting(false)
            setDone(true)
            setPhrase('Une erreur est survenue. Veuillez réessayer. Si le problème persiste, contactez-nous.')

        }

    }

    useEffect(() => { }, [waiting, done, phrase]);



    return <div className="ForgotPassword">
        {
            !done ?
                <>
                    <p>Mot de passe oublié</p>
                    <p>Entrez votre adresse mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.</p>
                    <input className='input' type="text" placeholder="email" onChange={(value) => { setEmail(value.target.value) }}></input>
                    <button onClick={() => { sendEmailReinit(email) }}>Envoyer</button>
                </> : <><p>{phrase}</p><button onClick={() => { navigate('/LogIn') }}>Retour</button></>
        }


    </div>
}

export default ForgotPassword;