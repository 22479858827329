import NavigationBar from '../../component/NavigationBar/NavigationBar';
import Header from '../../component/header/Header'
import './style.css';
import { useState, useEffect } from 'react';
import { auth, db } from '../../firebase.config';
import { getDocs, collection, doc, getDoc, query, where } from 'firebase/firestore';
import Vignette from '../../component/vignette/activite';
import phone from '../../assets/icones/phone.png'

function MesEscapades() {
    const [activite, setActivite] = useState([]);
    const [modify, setModify] = useState(false);

    async function init() {
        var lesActivites = []
        try {
            const currentUser = await getDocs(query(collection(db, 'Utilisateurs'), where('uId', '==', auth.currentUser.uid)));
            console.log(currentUser.docs[0].id)
            const res = await getDocs(query(collection(db, 'Activites'), where('idUser', '==', currentUser.docs[0].id)))
            res.forEach(async (doc) => {
                lesActivites.push(doc)

            })
            setActivite(lesActivites)


        } catch (error) {
            console.log('ERRRRRORRRR')
            console.log(error)

        }


    }


    useEffect(() => {
        init()
    }, [modify])

    return <div className="MesEscapades">

        <NavigationBar />
        <main >
            <Header titre={"Mes Escapades"} />
            <div className='lesVignettes' >
                {
                    modify && <><div className='popUp'>
                        <div className='contain'>
                            <h2>Vous ne pouvez pas modifier une Escapade seul </h2>
                            <p>En effet, pour des raisons évidentes d'organisation, toutes "Modification", ou "Annulation", d'une activitée doit être notifié à notre secrétaire. Afin que celle-ci puisse organiser auprés des résidents cette annulation. Vous pouvez contacter la résidence de Baudel au 03 25 03 08 47. </p>
                            <p>Ouvert du Lundi au Vendredi, de 9:00 à 12:00 et de 14:00 à 18:00</p>
                            <a href="tel: 03 25 03 08 47"><button className='telephone'><p>Je telephone</p> <img src={phone} alt="phone" /></button></a>
                            <button className='Fermer' onClick={() => { setModify(false) }}>J'ai compris </button>
                        </div>
                    </div></>
                }
                {activite.map((doc, key) => {
                    return <Vignette doc={doc} key={key} mine={true} modifier={() => { setModify(true) }} />

                })}


            </div>
        </main>
    </div>
}
export default MesEscapades;