import './style.css';
import { useNavigate } from 'react-router-dom';
import proposer from '../../assets/icones/add-violet.png';
import mesEscapades from '../../assets/icones/voyageur-violet.png';
import calendrier from '../../assets/icones/calendrier-violet.png';

function NavigationBar() {


    const navigate = useNavigate();
    return <div className="NavigationBar">
        <button onClick={() => { console.log('coucou'); navigate("/proposer") }}> <img src={proposer} alt="add logo" /><p>Proposer une escapade</p></button>
        <button onClick={() => { console.log('coucou'); navigate("/mes-escapades") }}> <img src={mesEscapades} alt='mes escapades' /><p>Mes escapades</p></button>
        <button onClick={() => { console.log('coucou'); navigate("/calendrier") }}><img src={calendrier} alt="calendrier" /><p>Calendrier des escapades</p></button>
    </div>
}

export default NavigationBar;