import NavigationBar from '../../component/NavigationBar/NavigationBar';
import Header from '../../component/header/Header';
import './style.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { CiStar } from 'react-icons/ci';
import { useEffect, useState } from 'react';
import Etoiles from '../../component/formulaire/etoiles/etoiles';
import CategorieSelector from '../../component/formulaire/categorieSelecor/categorieSelector';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import euro from '../../assets/icones/activités/prix.png';
import pouce from '../../assets/icones/like.png';
import car from '../../assets/icones/car.png';
import police from '../../assets/icones/policeman.png';
import check from '../../assets/icones/check.png'
import { db, auth } from '../../firebase.config';
import { addDoc, collection, getDocs, where, query } from 'firebase/firestore';
import Waiter from '../../component/waiter/waiter'
import { useNavigate } from 'react-router-dom';

function Proposer() {
    const navigate = useNavigate();
    const [etoile, etoileState] = useState(0);
    const [categorie, categorieState] = useState();
    const [dateActivite, DateActiviteState] = useState();
    const [heureDepart, heureDepartState] = useState();
    const [heureDArrive, heureDArriveState] = useState();
    const [nomActivite, setNomActivite] = useState();
    const [frais, setFrais] = useState(0);
    const [nombrePlaces, setNombrePlaces] = useState();
    const [textComplementaire, setTextComplementaire] = useState('');
    const [lieu, setLieu] = useState();

    //controllers: 
    const [waiting, setWaiting] = useState(false)
    const [phraseError, setPhraseError] = useState()
    const [sucess, setSucess] = useState(false);


    async function addActivite() {
        setWaiting(true);
        if (etoile != 0 && categorie != null && dateActivite != null && heureDepart != null && heureDArrive != null && nomActivite != null && nombrePlaces != null && lieu != null) {

            try {

                const idUser = await getDocs(query(collection(db, 'Utilisateurs'), where('mail', '==', auth.currentUser.email))).then((docs) => { return docs.docs.length > 0 ? docs.docs[0].id : null })

                if (idUser != null) {
                    const res = await addDoc(collection(db, 'Activites'), {
                        indice: etoile,
                        categorie: categorie,
                        dateActivite: dateActivite,
                        heureDepart: heureDepart,
                        heureDArrive: heureDArrive,
                        nomActivite: nomActivite,
                        frais: frais,
                        nombrePlaces: nombrePlaces,
                        textComplementaire: textComplementaire,
                        idUser: idUser,
                        inscrits: [],
                        lieu: lieu
                    });
                }

                setSucess(true)

            } catch (e) {
                setPhraseError('Oups... Il y a eu une erreur ... ')
                console.log('error ' + e.toString())


            }


        } else {
            console.log('ERROR FORM')
            console.log(dateActivite != null)
            let phrase = 'Veuillez remplir les champs suivants : '
            if (etoile == 0) {
                phrase += 'Indice d\'accessibilité, '
            }
            if (categorie == null) {
                phrase += 'Catégorie, '
            }
            if (dateActivite == null) {
                phrase += 'Date de l\'activité, '
            }
            if (heureDepart == null) {
                phrase += 'Heure de départ, '
            }
            if (heureDArrive == null) {
                phrase += 'Heure d\'arrivée, '
            }
            if (nomActivite == null) {
                phrase += 'Nom de l\'activité, '
            }
            if (nombrePlaces == null) {
                phrase += 'Nombre de places, '
            }
            if (lieu == null) {
                phrase += 'Lieu, '
            }
            setPhraseError(phrase)

        }
        setWaiting(false);
    }



    useEffect(() => {
    }, [etoile, categorie])
    return <div className="Proposer">
        <NavigationBar />
        <main>
            {waiting ? <Waiter /> : null}
            <Header titre={"Proposer une escapade"} />
            {!sucess ? <div className='FormCadre'>
                <div className='row row1'>
                    <input type="text" placeholder="Nom de l'activité" onChange={(value) => setNomActivite(value.target.value)} />
                    <input type='text' placeholder='Lieu' onChange={(value) => setLieu(value.target.value)} />
                    <div className='indiceAccess'>
                        <div className='titreEtInterogation'>
                            <p data-hover="ca marche ? ">Indice d'accessibilité</p>
                            <p className='icon'><BsQuestionCircle /></p>
                        </div>
                        <Etoiles etoiles={etoile} state={(value) => { etoileState(value); }} />
                    </div>
                </div>
                <div className='row row2'>
                    <CategorieSelector assign={(value) => categorieState(value)} value={categorie} />

                </div>
                <div className='row row3'>
                    <DatePickerCustom dateSelected={(value) => { DateActiviteState(value) }} />
                    <HourCustomPicker assign={(value) => { heureDepartState(value) }} text="A quelle heure passerez-vous chercher la personne ?" />
                    <HourCustomPicker assign={(value) => { heureDArriveState(value) }} text="A quelle heure allez-vous revenir ?"></HourCustomPicker>
                </div>
                <div className='row row4'>
                    <CoutPicker setPrice={(value) => setFrais(value)} />
                    <div></div>
                    <div></div>
                </div>
                <div className='row row5'>
                    <div className='placeDispo'><input type="number" placeholder='nombre de places disponibles' pattern='[0-9]' onChange={(value) => setNombrePlaces(value.target.value)} /><img src={car} alt="voiture" /></div><div></div><div></div>
                </div>
                <div className='row row6'>
                    <input type="textarea" placeholder='Texte complementaire' onChange={(value) => setTextComplementaire(value.target.value)} />
                    <div></div><div></div>

                </div>
                <div className='row row7'>
                    <div className='conditions'><p onClick={() => { navigate('/CGU') }}>J'accepte les conditions générales d'utilisation</p><input type="checkbox" name="" id="" /></div><div></div>
                </div>
                <div className='row row8'>
                    {phraseError != null ? <p className='error'>{phraseError}</p> : null}<button onClick={() => addActivite()}><p>Soumettre</p></button>

                </div>

            </div> : <ActiviteOk />}
        </main>
    </div>
}
export default Proposer;

function DatePickerCustom(props) {
    const [cliked, clikedState] = useState(false);
    const [date, dateState] = useState();
    const [hour, hourState] = useState();
    const [goToHour, goToHourState] = useState(false)

    function dateSelected() {
        console.log('date selected : ' + date.toLocaleDateString())
        props.dateSelected(date);


    }
    function daySelected(value) {
        dateState(value);
        goToHourState(true);

    }
    useEffect(() => {
        if (date != null && hour != null) {
            dateSelected()
        }


    }, [cliked, date], hour)

    return <div className='DatePickerCustom'>
        <button className='datePicker' onClick={() => { clikedState(!cliked); goToHourState(false) }}><p>Date de l'activité</p><span></span></button>
        {
            cliked && !goToHour ? <div>
                <DayPicker className='datePickerPopUp' mode="single"
                    selected={date}
                    onSelect={(value) => daySelected(value)} />
            </div> : null
        }
        {
            date != null ? <p className='dateAffichage'>{date.toLocaleDateString()} {hour != null ? <span> à {hour}</span> : null}</p> : null
        }
        {
            cliked && goToHour ? <HourPicker assign={(value) => { hourState(value); goToHourState(false); clikedState(false) }} open={() => clikedState(false)} /> : null

        }

    </div>

}

function HourPicker(props) {
    const [heure, heureState] = useState();
    const [minutes, minutesState] = useState();
    const [timeIsValid, timeIsValidState] = useState();

    function verify() {
        if (heure >= 0 && heure < 24 && minutes >= 0 && minutes <= 59) {
            timeIsValidState(true);
            props.assign(`${heure} : ${minutes}`)
        } else {
            timeIsValidState(false);

        }
    }
    useEffect(() => { }, [timeIsValid]);


    return <div className='hourPicker'>
        <p className='titre'>A quelle heure ? <button className='close' onClick={() => { props.open() }}><div className='circle'><div className='trait1'></div><div className='trait2'></div></div></button></p>
        <div className="hour"><input type="number" onChange={(value) => { heureState(value.target.value) }} /><p> : </p><input type="number" onChange={(value) => { minutesState(value.target.value) }} />
            <button className='validHour' onClick={() => { verify() }}><p>ENREGISTRER</p></button></div>
        {timeIsValid === false ? <p className='erreur'>heure non valide</p> : null}</div>

}

function HourCustomPicker(props) {
    const [open, openState] = useState(false);
    const [heure, heureState] = useState();
    useEffect(() => { }, [heure])
    useEffect(() => { }, [open])

    return <div >
        <button className='datePicker' onClick={() => { openState(!open) }}><p>{props.text}</p></button>
        {open ? <HourPicker assign={(value) => { props.assign(value); openState(false); heureState(value) }} open={() => { openState(false) }} /> : null}
        {heure != null ? <p className='heure'>à {heure}</p> : null}

    </div>

}
function CoutPicker(props) {
    const [open, openState] = useState(false);
    const [first, firstState] = useState(true);

    useEffect(() => {

    }, [open])


    return <div className='cout'>
        <input type="number" placeholder='Coût' pattern='[0-9]' onClick={() => { openState(!open) }} onChange={(value) => props.setPrice(value.target.value)} />
        <img src={euro} alt="symbol euro" />
        {open && first ? <div className='popUp'>
            <img src={police} alt="policier" className='policier'></img>
            <p>Escapade est un projet de proposition de service GRATUIT. Ici, il s'agit de faire savoir à la personne qui viendra avec vous l'éventuel prix de l'activité (place de cinéma, de théâtre, de concert, ...).En rien, vous ne devez demander une participation pour l'essence, le péage autoroute, ou autres frais personnels qui ne sont pas en rapport direct avec l'activité. Cet argent ne vous sera pas remis.</p>
            <div className='accord'><button onClick={() => { openState(false); firstState(false) }}><p className='compris'>J'ai compris !</p><img className="pouce" src={pouce} alt="pouce en l'air" /></button></div>
        </div> : null}

    </div>

}

function ActiviteOk() {
    return <div className='FormCadre'>

        <h2>Felicitations</h2>
        <p>Votre activité a bien été enregistrée. Elle est désormait visible dans la résidence. Nous vous tiendrons informé par SMS, si un de nos résidents souhaite vous suivre dans cette escapade. <br>
        </br>
            Merci ! </p>
    </div>
}