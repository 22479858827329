import { CiStar } from 'react-icons/ci';
import { useEffect, useState } from 'react';
import './style.css';

function Etoiles(props) {

    function selectPhrase(index) {
        const phrase = ['Vous devez selectionner un indice de mobilité', 'Accessibilité limitée', 'Accessibilité moyenne', 'Accessibilité acceptable', 'Accessibilité bonne', 'Accessibilité optimale'];
        switch (index) {
           case 1:
                return <p className='avis'>{phrase[1]}</p>
            case 2:
                return <p className='avis'>{phrase[2]}</p>
            case 3:
                return <p className='avis'>{phrase[3]}</p>
            case 4:
                return <p className='avis'>{phrase[4]}</p>
            case 5:
                return <p className='avis'>{phrase[5]}</p>
                
            default:
                return <p className='avis'>Vous devez selectionner un indice de mobilité</p>
        }
    }
    const [etoiles, etoilesState] = useState();
    useEffect(() => {
        etoilesState(props.etoiles);
    })

    return <div className='indice' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {selectPhrase(etoiles)}
        <div className='etoiles'>
            <button onClick={() => props.state(1)}><CiStar className={etoiles >= 1 ? 'active' : 'inactive'} /></button>
            <button onClick={() => props.state(2)}><CiStar className={etoiles >= 2 ? 'active' : 'inactive'} /></button>
            <button onClick={() => props.state(3)}> <CiStar className={etoiles >= 3 ? 'active' : 'inactive'} /></button>
            <button onClick={() => props.state(4)}> <CiStar className={etoiles >= 4 ? 'active' : 'inactive'} /></button>
            <button onClick={() => props.state(5)}><CiStar className={etoiles >= 5 ? 'active' : 'inactive'} /></button>

        </div></div>
}
export default Etoiles;