import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Acceuil from './screens/Acceuil/Acceuil';
import LogIn from './screens/LogIn/LogIn';
import Proposer from './screens/Proposer/Proposer';
import MesEscapades from './screens/MesEscapades/MesEscapades';
import CalendrierEscapades from './screens/CalendrierEscapades/CalendrierEscapades';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import ConditionsGenerales from './screens/conditionsGenerales/ConditionsGenerales';

function Router() {
  return (<main>
    <BrowserRouter>
      <Routes>
        <Route path = "/acceuil" element={<Acceuil/>}/ >
          
          <Route path= '/proposer' element ={<Proposer/>}/>
          <Route path ="/mes-escapades" element= {<MesEscapades />}/>
          <Route path ="/calendrier" element= {<CalendrierEscapades />}/>
          <Route path ="/forgotpass" element= {<ForgotPassword />}/>
          <Route path ="/cgu" element= {<ConditionsGenerales />}/>
          <Route path="*" element={<LogIn/>}/>
      </Routes>
    </BrowserRouter>
    </main>
      
  );
}

export default Router;