import NavigationBar from "../../component/NavigationBar/NavigationBar";
import './style.css';
import logo from '../../assets/logo/LOGO1.png';
import { useEffect, useState } from "react";
import vieux from '../../assets/icones/personnes-agees.png'
import { Navigate, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase.config";
import { signInWithEmailAndPassword } from 'firebase/auth'



function LogIn() {
    const [waiting, setWaiting] = useState(false);
    const [phrase, setPhrase] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate()


    async function connection() {
        setWaiting(true);
        try {
            console.log(email);
            console.log(password)
            const reponse = await signInWithEmailAndPassword(auth, email, password);
            console.log(reponse.user);
            navigate('/acceuil');



        } catch (e) {
            console.log('ERROR : ' + e.toString());
            setPhrase('Utilisateur non-reconnu');
            setWaiting(false);

        }
    }



    useEffect(() => {
    }, [waiting,])
    return <div className="LogIn">
        {waiting ? <Loading /> : null}
        {!waiting ? <main>
            <button className="logo"><img src={logo} alt="logo" /></button>
            <input className="email" type="text" placeholder="email" onChange={(value) => setEmail(value.target.value)}></input>
            <input className="motDePasse" type="password" placeholder="mot de passe" onChange={(value) => setPassword(value.target.value)}></input>
            {phrase ?? <p className="phraseError">{phrase}</p>}
            <p onClick={() => { navigate('/forgotpass') }} className="motDePasseOublie">Mot de passe oublié ?</p>

            <button className="submit" onClick={() => { connection() }}>C'est parti ! </button>
        </main> : null}

    </div>
}

export default LogIn;



function Loading() {
    return <div className="waiting">
        <img src={logo} alt="logo escapade"></img>
        <p>Connection </p>
    </div>
}