import './style.css'

function ConditionsGenerales() {
    return <div className='CGU'><h1>Conditions d'utilisation du service "Escapades" </h1>
        <h2>Introduction</h2>
        <p>Bienvenue sur le site "Escapades". En utilisant notre site, vous acceptez d'être lié par les présentes conditions d'utilisation (les "Conditions"). Si vous n'êtes pas d'accord avec ces Conditions, veuillez ne pas utiliser notre site.</p>
        <h2>Description du service</h2>
        <p>Le service "Escapades" est un site web qui permet aux Escapadeurs de consulter leur espace personnel et de soumettre des escapades. La saisie des escapades est simple et intuitive, et le site est conçu pour s'adapter à toutes les tailles d'écran.</p>
        <h2>Inscription</h2>
        <p>Pour utiliser le service "Escapades", vous devez vous inscrire en fournissant vos informations personnelles telles que votre nom, votre adresse e-mail et votre numéro de téléphone. Vous êtes responsable de la véracité et de l'exactitude de ces informations.</p>
        <h2>Contenu</h2>
        <p>Le contenu publié sur le site "Escapades" est soumis à des restrictions. Vous ne devez pas publier de contenu qui est illégal, vulgaire, offensant ou diffamatoire. Nous nous réservons le droit de retirer tout contenu à tout moment et sans préavis.</p>
        <h2>Propriété intellectuelle</h2>
        <p>Le contenu publié sur le site "Escapades" est protégé par le droit d'auteur et les droits de propriété intellectuelle. Vous ne pouvez pas copier, modifier, distribuer, transmettre, afficher, publier ou vendre tout contenu du site "Escapades" sans notre autorisation écrite préalable.</p>
        <h2>Liens vers d'autres sites</h2>
        <p>Le site "Escapades" peut contenir des liens vers d'autres sites. Nous ne sommes pas responsables du contenu de ces sites. Nous vous recommandons de lire les conditions d'utilisation de ces sites avant de les utiliser.</p>
        <h2>Modification des conditions</h2>
        <p>Nous nous réservons le droit de modifier ces Conditions à tout moment. Nous vous recommandons de consulter régulièrement ces Conditions pour vous tenir au courant de toute modification. En utilisant notre site, vous acceptez d'être lié par les Conditions modifiées.</p>
        <h2>Modification du service</h2>
        <p>Nous nous réservons le droit de modifier ou de suspendre le service "Escapades" à tout moment. Nous ne serons pas responsables de tout dommage résultant de la modification ou de la suspension du service "Escapades".</p>
        <h2>Limitation de responsabilité</h2>
        <p>Nous ne serons pas responsables de tout dommage résultant de l'utilisation du service "Escapades". Nous ne sommes pas responsables des dommages indirects, spéciaux, accessoires ou consécutifs, y compris, sans limitation, les pertes de profits, de données, d'utilisation ou d'autres pertes intangibles, résultant de l'utilisation du service "Escapades".</p>
        <h2>Indemnisation</h2>
        <p>Vous acceptez de nous indemniser de toute réclamation, perte, dommage, coût ou dépense, y compris les honoraires d'avocat, résultant de votre utilisation du service "Escapades" ou de votre violation des présentes Conditions.</p>
        <h2>Loi applicable</h2>
        <p>Ces Conditions sont régies par la loi française. Vous acceptez de vous soumettre à la compétence exclusive des tribunaux français pour tout litige relatif à ces Conditions.</p>
        <h2>Contact</h2>
        <p>Pour toute question ou commentaire, veuillez nous contacter à l'adresse suivante :</p>
        <br />
        <a href="mailto:escapadesbaudel@gmail.com">COURRIEL </a>
        <br />
        <a href="tel:+333 25 03 08 47">TELEPHONE </a>



    </div>
}
export default ConditionsGenerales

