import { useEffect, useState } from 'react';
import { BsCameraReels } from 'react-icons/bs'
import { FaTheaterMasks, FaWalking } from 'react-icons/fa'
import { ImMusic } from 'react-icons/im'
import './style.css';

function CategorieSelector(props) {
    function displayLogo(value) {
        switch (value) {
            case "cinema":
                return <span className='logo'><BsCameraReels /></span>
            case "theatre":
                return <span className='logo'><FaTheaterMasks /></span>
            case "concert":
                return <span className='logo'><ImMusic /></span>
            case "balade":
                return <span className='logo'><FaWalking /></span>
            case "autre":
                return <div className='infoCat'>
                    <p>Nous avons besoin de plus d'informations sur l'activité</p>
                    <label htmlFor="titre" >Catégorie : </label><input name='titre' placeholder='cimetière, messe, ...'></input>
                </div>
            default:
                return <span className='aucccune'></span>
        }
    }
    return <div className="selectorCate">

        <select onChange={(value) => {
            props.assign(value.target.value)
        }}>
            <option value="">Choisissez une cathégorie</option>
            <option value="cinema">Cinema</option>
            <option value="theatre">Theatre</option>
            <option value="concert">Concert</option>
            <option value="balade">Balade</option>
            <option value="autre">Autre</option>
        </select>
        {
            <p>{displayLogo(props.value)}</p>
        }
    </div>
}
export default CategorieSelector;