import logo from '../../assets/logo/LOGO1-logo-seul.png';
import logoMobil from '../../assets/logo/LOGO1.png';
import './style.css';
import NavigationBar from '../../component/NavigationBar/NavigationBar';
import { useNavigate } from 'react-router-dom';
import Header from '../../component/header/Header';
import add from '../../assets/icones/add-violet.png';
import voyageur from '../../assets/icones/voyageur-violet.png';
import calendrier from '../../assets/icones/calendrier-violet.png'

function Acceuil() {
  const navigate = useNavigate()
  return <div className="Acceuil">
    <NavigationBar />
    <main className="Header">
      <Header titre={"ACCEUIL"} />
      <div className='NavigationMobil'>
        <div className='logoMobil'>
          <img src={logoMobil} alt="" />
        </div>
        <div className='proposer' onClick={() => { navigate('/proposer') }}>
          <p>Proposer une escapade</p>
          <img src={add} />
        </div>
        <div className='proposer' onClick={() => { navigate('/mes-escapades') }}>
          <p>Mes escapades</p>
          <img src={voyageur} />
        </div>
        <div className='proposer' onClick={() => { navigate('/calendrier') }}>
          <p>Calendrier des Escapades</p>
          <img src={calendrier} />
        </div>
        <div className='mesEscapades'></div>
        <div className='calendrierEscapades'></div>
      </div>

      <div className='descriptionOrdinateur'>
        <p>Le projet Escapade apporte une bouffée d'air frais aux résidents de la résidence Eugénie de Baudel à Chaumont. Cette initiative novatrice offre une variété d'activités divertissantes pour les résidents grâce à un site internet facile à utiliser.

          Les accompagnants peuvent s'inscrire en toute simplicité avec les identifiants fournis par le secrétariat de la résidence. Ils peuvent alors proposer des activités passionnantes qui sont affichées en temps réel sur une télévision installée dans la résidence.

          Les résidents peuvent s'inscrire aux activités qui les intéressent en se rendant simplement au secrétariat. L'accompagnant recevra alors un SMS pour le prévenir que le résident l'attend à la résidence.

          Le projet Escapade est conçu pour enrichir la vie des résidents en leur offrant des expériences. Nous sommes convaincus que ce projet apportera une touche supplémentaire de bonheur et de divertissement aux résidents de la résidence Eugénie de Baudel."

          "Nous sommes heureux de vous présenter le projet Escapade, un projet innovant créé par Vincent Bardin et Celine Bremard. Il vise à offrir des activités intéressantes et amusantes aux résidents de la résidence Eugénie de Baudel à Chaumont.

          Grâce à un site internet facile à utiliser, les accompagnants peuvent s'inscrire et proposer des activités passionnantes aux résidents. Les identifiants fournis par le secrétariat de la résidence permettent une connexion simple et rapide au site.

          Une télévision est installée dans la résidence pour afficher en temps réel les activités proposées. Si un résident est intéressé, il peut se rendre au secrétariat pour s'inscrire. L'accompagnant recevra alors un SMS le informant qu'un résident l'attend à la résidence.

          Nous sommes convaincus que ce projet apportera une nouvelle dimension à la vie des résidents, en leur offrant des expériences enrichissantes et inoubliables. Nous espérons que le projet Escapade sera un succès pour toutes les personnes impliquées.</p>


      </div>
    </main>
  </div>
}
export default Acceuil;

